import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

const TermsConditions = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "cta", // cta, account, null
          buttonText: "Get started"
        }}
      >
        <div className="inner-banner pt-29 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h3 className="title gr-text-2 mb-9">
                    Pear Commerce Pixel Terms & Conditions
                  </h3>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-21">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                    <p className="gr-text-9">
                      This Retailer Partnership Agreement (the "Agreement”),
                      effective as of the date the last Party signs this
                      Agreement (the "Effective Date”), is made by and between
                      Pear Commerce, Inc., with a principal place of business at
                      3349 Girard Ave S, Minneapolis, MN 55408 ("Pear”), and the
                      retailer entering into this Agreement and installing the
                      pixel on its site ("Retailer”), each a “Party” and
                      collectively the “Parties”.
                      <br></br>
                      <br></br>
                      <h5> Recitals</h5>
                      Pear connects CPGs or advertising agencies working on
                      their behalf (collectively, “CPG Sellers”) advertising to
                      consumers (“Shoppers”) and the stores at which they shop,
                      including Retailer.
                      <br></br>
                      <br></br>
                      Pear uses existing CPG digital advertising to convert
                      Shoppers into web traffic that it redirects to partner
                      retailers.
                      <br></br>
                      <br></br>
                      Retailer desires to receive Pear’s traffic and as
                      consideration will return certain transaction data to
                      Pear.
                      <br></br>
                      <br></br>
                      NOW, THEREFORE, Retailer and Pear hereby agree as follows:
                      <br></br>
                      <br></br>
                      <ol>
                        <li>Partnership Details.</li>
                        <ol>
                          <li>
                            Obligations. Pear will operate its marketing and
                            data platform (the “Platform” or “Pear Platform”) to
                            connect and drive web traffic from certain Shoppers
                            interested in a CPG product to the sites and
                            platforms of Retailer offering that product (the
                            "Traffic”). Retailer will provide Pear with
                            transaction data from shoppers directed to Retailer
                            by Pear via its pixel.{" "}
                          </li>
                          <li>
                            Traffic. Pear will send Traffic to Retailer’s
                            website or other digital channels with options to
                            purchase CPG products (the “Retailer Site”) free of
                            charge. As consideration, Retailer shall install
                            Pear’s pixel on the Retailer Site (or other mutually
                            agreed upon method) and provide session-specific
                            transaction data, not including Shoppers"#personally
                            identifiable information such as names or email
                            addresses (“Retailer Data”). Pear shall not at any
                            time record or observe web traffic not originally
                            provided by Pear Platform
                          </li>
                        </ol>
                        <br></br>

                        <li>Intellectual Property.</li>
                        <ol>
                          <li>
                            Pear Owns IP in Pear Platform. Pear and its
                            licensors reserve all rights, including but not
                            limited to all IP Rights, in and to the Platform
                            including any updates to the Platform. "IP Rights”
                            means U.S. and non-U.S. patents, trademarks,
                            copyrights, trade secrets, software, databases, and
                            any other similar items recognized as intellectual
                            property or proprietary rights in any jurisdiction.
                            For any software or code provided by Pear under this
                            Agreement, Pear grants Retailer a non-exclusive,
                            limited, non-transferable, nonsublicense-able,
                            license for the Term of this Agreement to use the
                            software or code, in object-code only, for
                            Retailer"s internal business purposes only and only
                            for the purpose of making permitted use of the
                            Platform, and further subject to any as described in
                            any documentation or terms accompanying such
                            additional software
                          </li>
                          <li>
                            Retailer Data Ownership. Retailer owns all rights,
                            interest, and title in and to the Retailer Data.
                            Retailer shall not send any directly identifiable
                            personally identifiable information including name,
                            email address, home address, and phone number.
                            Retailer hereby grants and agrees to grant to Pear a
                            perpetual, royalty-free, irrevocable license to use,
                            display, modify, create derivative works, and
                            transmit Retailer Data, in whole or in part, and
                            including all IP Rights therein, in connection with
                            delivery and improvement of the Pear Platform.
                          </li>
                          <li>
                            Mutual Trademark License and Third Party Items. Each
                            Party hereby grants the other Party, only for the
                            Term, a limited, non-exclusive, right and license to
                            the trademarks and trade names of such Party as
                            reasonably necessary to perform under this
                            Agreement, for Pear to list Retailer as a
                            participating retailer in its business and marketing
                            materials, to operate and promote the Platform, and
                            promote and fulfill individual offers from CPG
                            Sellers. Each Party shall use reasonable efforts to
                            follow the style and other guides regarding the
                            trademarks of the other Party. During the Term of
                            this Agreement, Retailer may receive or access items
                            owned or provided by third parties (for example a
                            CPG Seller"s trademarks or logos or
                            copyright-protected text and images)(collectively
                            "Third Party Items”). Such items are owned by the
                            applicable third party, are licensed and not sold,
                            and Retailer shall use such Third Party Items in
                            compliance with any and all third party terms and
                            conditions (for example, a third party"s description
                            of how a trademark may be displayed). Pear is not
                            responsible for and makes no representation or
                            warranty regarding any Third Party Items.
                          </li>
                        </ol>
                        <br></br>
                        <li>
                          Term and Termination; Survivability. The Initial Term
                          of this Agreement is two (2) years (the "Initial
                          Term”) from the Effective Date. Upon expiration of the
                          Initial Term, this Agreement will automatically be
                          renewed for additional two-year terms (each a "Renewal
                          Term”). The Initial Term and any Renewal Terms are
                          collectively referred to herein as the "Term.” In any
                          Term, either Party may terminate this Agreement upon
                          thirty (30) days prior written notice in the event:
                          (i) of any material breach by the other Party which
                          continues after thirty (30) days of written notice of
                          said breach, or (ii) the other Party becomes
                          insolvent, makes an assignment for the benefit of
                          creditors, or files a petition in bankruptcy. Upon
                          termination or expiration of this Agreement, the
                          duties of the Parties shall cease, except for the
                          following sections which shall survive expiration
                          and/or termination: 3, 5, 6, 7, 8, and 9.
                        </li>
                        <br></br>
                        <li>Representations and Warranties.</li>
                        <ol>
                          <li>
                            Platform Operation. Pear shall, with the assistance
                            of third parties (for example third party hosting
                            entities) operate, make available, and improve the
                            Platform. Pear may modify, revise, or discontinue
                            elements or features of its own Platform at any
                            time. Pear may work with, or decline to work with,
                            different CPG Sellers, as desired by Pear in its
                            business judgement. Pear Commerce may contract and
                            collaborate with third party vendors to assist Pear
                            in its performance under this Agreement (for
                            example, Pear may engage a third party hosting
                            service, Pear may purchase advertising from third
                            parties, and Pear may send purchase events to ad
                            networks).{" "}
                          </li>
                          <li>
                            Pear Representations and Warranties. Pear represents
                            and warrants: (i) that it owns or has licensed all
                            rights necessary to provide the Platform and that
                            the Platform does not infringe any US Intellectual
                            Property Right of any third party; (ii) that it will
                            comply with all applicable U.S. laws and regulations
                            related to its performance under this Agreement, and
                            (iii) that it is authorized and has all authority
                            necessary to enter into and perform under this
                            Agreement and that this Agreement constitutes a
                            binding and enforceable agreement between the
                            Parties. In the event that the Platform, or any
                            portion thereof, is alleged to be or is enjoined or
                            is held to be an infringement, Pear shall have the
                            option to, at Pear’s expense and as Retailer"s sole
                            and exclusive remedy, either (a) procure for
                            Retailer the right to continue to use the Platform
                            and/or Documentation; (b) modify the items in
                            question so that it becomes non-infringing, or (c)
                            terminate this Agreement and return to Retailer any
                            pre-paid fees. Pear does not represent or warrant
                            that any particular Shopper is a high-quality,
                            desirable, or authentic consumer. Pear is not
                            responsible or liable for: (i) proper inventory and
                            other logistics necessary to sell, fulfill, and
                            delivery any applicable CPG products advertised by
                            CPG Sellers, (ii) any dispute between Retailer and
                            any CPG Seller, or (iii) any act or omission of any
                            Shopper, nor is Pear responsible for any at or
                            omission of any CPG Seller or of Retailer. Retailer
                            understands and acknowledges that CPG Sellers design
                            advertisements to promote their various CPG products
                            at their own expense and responsibility
                          </li>
                          <li>
                            Retailer Representations and Warranties. Retailer
                            represents and warrants that it: (i) owns or has
                            licensed all rights necessary to perform under this
                            Agreement and that Retailer"s performance will not
                            infringe any US Intellectual Property Right of any
                            third party; (ii) will comply with all applicable
                            U.S. laws and regulations related to its performance
                            under this Agreement including but not limited to
                            its collection and use of data provided by Shoppers
                            through the Retailer Site; (iii) is authorized and
                            has all authority necessary to enter into and
                            perform under this Agreement and that this Agreement
                            constitutes a binding and enforceable agreement
                            between the Parties; (iv) will accurately reflect
                            all interactions and transactions with Shoppers
                            provided to the Retailer Site through the Pear
                            Platform; and will not disable or interfere with any
                            tracking mechanism, including but not limited to
                            Pear’s pixel, that measures or sends Traffic or
                            Retailer Data.
                          </li>
                          <li>
                            No Other Warranties; Disclaimer. THE AFFIRMATIVE
                            REPRESENTATIONS AND WARRANTIES OF BOTH PARTIES THAT
                            ARE EXPRESSLY SET FORTH IN THIS AGREEMENT ARE THE
                            ONLY REPRESENTATIONS AND WARRANTIES PROVIDED UNDER
                            OR RELATED TO THIS AGREEMENT. NOTWITHSTANDING
                            ANYTHING ELSE, TO THE MAXIMUM EXTENT PERMITTED BY
                            APPLICABLE LAW, SUBJECT ONLY TO THE EXPRESS
                            WARRANTIES PROVIDED UNDER THE AGREEMENT, THE
                            PLATFORM, THE RETAILER TECHNOLOGY, ALL INFORMATION
                            AND ITEMS PROVIDED BY CPG SELLERS, CONSUMER TRAFFIC
                            AND INFORMATION AND DATA PROVIDED BY CONSUMERS, AND
                            CONFIDENTIAL INFORMATION, ARE PROVIDED ON AN !AS-IS,
                            AS-AVAILABLE” BASIS. BOTH PARTIES EXPRESSLY DISCLAIM
                            ANY AND ALL OTHER WARRANTIES, EXPRESS OR IMPLIED,
                            INCLUDING, WITHOUT LIMITATION: (A) ANY AND ALL
                            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                            PARTICULAR PURPOSE; (B) ANY WARRANTY REGARDING
                            RESULTS OBTAINABLE OR TO BE OBTAINED; AND (C) ANY
                            WARRANTY OF UNINTERRUPTED OR ERROR-FREE OPERATION OF
                            OR ACCESS TO THE PLATFORM AND/OR THE RETAILER
                            TECHNOLOGY. BOTH PARTIES AS WELL AS CPG SELLERS
                            RESERVE THE RIGHT TO CORRECT ANY TYPOGRAPHICAL
                            ERRORS IN OFFER PRICING OR TERMS, AND A PARTY SHALL
                            NOT BE REQUIRED TO HONOR SUCH TYPOGRAPHICAL ERRORS
                            APPEARING IN ANY OFFER POSTED ON THE PLATFORM.
                            NEITHER PARTY REPRESENTS NOR WARRANTS THAT THE
                            OFFERS OR PARTICULAR CPG PRODUCTS ARE LEGAL, VALID
                            OR APPROPRIATE IN ALL JURISDICTIONS; OFFERS AND CPG
                            PRODUCTS ARE VOID WHERE PROHIBITED. RETAILER
                            UNDERSTANDS AND ACKNOWLEDGES THAT THIS IS A
                            NON-EXCLUSIVE AGREEMENT, AND THAT PEAR WILL SEND
                            CONSUMER TRAFFIC TO MULTIPLE ENTITIES, INCLUDING BUT
                            NOT LIMITED TO ENTITIES THAT MAY BE COMPETITORS OF
                            RETAILER. PEAR IS AN ENTITY BASED IN THE U.S. AND
                            INTENDS TO PROMOTE OFFERS TO CONSUMERS AND RETAILERS
                            IN THE U.S. ONLY. ALTHOUGH THE PLATFORM AND/OR
                            OFFERS MAY BE VIEWED OUTSIDE OF THE U.S., SUCH
                            OUTSIDE U.S. INDIVIDUALS MAY BE BLOCKED. PEAR DOES
                            NOT AGREE TO BE SUBJECT TO ANY LAWS OUTSIDE OF THE
                            U.S. PEAR DISCLAIMS AND IS NOT RESPONSIBLE OR LIABLE
                            FOR: THE CONTENT OF ANY OFFER FROM A CPG SELLER OR
                            CPG SELLER"S ADVERTISEMENT; DEFECTS OR ANY OTHER
                            PROBLEM WITH CPG PRODUCT(S); ACTS OR OMISSIONS OF
                            CONSUMERS; ACTS OR OMISSIONS OF CPG SELLERS.
                          </li>
                        </ol>
                        <br></br>
                        <li>
                          Mutual Limitation of Remedies. NEITHER PARTY WILL BE
                          LIABLE TO THE OTHER PARTY FOR SPECIAL, INCIDENTAL,
                          CONSEQUENTIAL, EXEMPLARY OR OTHER INDIRECT DAMAGES OR
                          FOR LOST PROFITS OR LOSS OF DATA ARISING OUT OF THIS
                          AGREEMENT, WHETHER IN CONTRACT, TORT, STRICT LIABILITY
                          OR OTHERWISE, EVEN IF SUCH PARTY HAS BEEN MADE AWARE
                          OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL
                          PEAR"S AGGREGATE LIABILITY ARISING UNDER THIS
                          AGREEMENT EXCEED $50,000.
                        </li>
                        <br></br>
                        <li>Indemnification</li>
                        <ol>
                          <li>
                            Pear shall defend, hold harmless, and indemnify
                            Retailer and each of its affiliates, officers,
                            directors, employees and agents from and against any
                            and all third party claims, demands, and actions,
                            and any liabilities, costs, damages, or expenses
                            resulting therefrom, including settlement costs,
                            court costs, and reasonable attorneys"#fees
                            ("Losses”) arising out of or relating to (i) a
                            breach of representations and warranties in this
                            Agreement or (ii) the violation of third party
                            intellectual property or proprietary rights by the
                            technology deployed by Pear.{" "}
                          </li>
                          <li>
                            Retailer shall defend, hold harmless, and indemnify
                            Pear, and each of their respective affiliates,
                            officers, directors, employees and agents from and
                            against any and all Losses arising out of or
                            relating to (i) a breach of representations and
                            warranties in this Agreement or (ii) the violation
                            of any privacy or third party intellectual property
                            or proprietary rights resulting from Pear’s use of
                            the Retailer Data.{" "}
                          </li>
                        </ol>
                        <br></br>
                        <li>Confidentiality</li>
                        <ol>
                          <li>
                            "Confidential Information” means the existence and
                            terms of this Agreement including all Orders, as
                            well as information in any form disclosed or made
                            available by a Party (the "Discloser”) to the other
                            Party (the "Recipient”) that the Recipient knows or
                            has reason to know is confidential information of
                            the Discloser shall include, without limitation:
                            information, data, statistics, programs, methods or
                            practices; response rates or statistics, demographic
                            information; vendor information; the current, future
                            and proposed products of services of the Discloser
                            as well as financial, proprietary, technical,
                            developmental research, operational, sales and
                            marketing information related thereto
                          </li>
                          <li>
                            Each Party agrees that for a period of at least five
                            (5) years, and for trade secret information for as
                            long as such item remains a trade secret, it shall
                            treat as confidential all Confidential Information
                            received from the other Party, shall not use such
                            Confidential Information except as expressly
                            permitted under this Agreement, and shall not
                            disclose such Confidential Information to any third
                            party without the other Party"s prior written
                            consent, except that a Party may disclose
                            Confidential Information to its contractors,
                            consultants or vendors assisting such Party so long
                            as such third parties are contractually required to
                            preserve the confidentiality thereof on terms at
                            least as strict as those in this Agreement, and such
                            Party is responsible for the acts and omission of
                            such third parties as if they were their own as to
                            Confidential Information. Confidential Information
                            may be shared with CPG Sellers and their agents to
                            the extent reasonably necessary for the provision of
                            the Platform and the measuring of Traffic. Each
                            Party shall take reasonable measures, and at least
                            the same precautions it takes to protect its own
                            confidential information, to prevent the disclosure
                            and unauthorized use of Confidential Information of
                            the other Party. Confidential Information may be
                            disclosed, reproduced, summarized or distributed
                            only in pursuance of the applicable recipient"s
                            business relationship with the Party disclosing such
                            Confidential Information. Each Party acknowledges
                            that unauthorized disclosure or use of the other
                            Party"s Confidential Information will cause
                            irreparable harm to such other Party. Each Party
                            agrees that money damages would be an insufficient
                            remedy for any breach of this Agreement and that the
                            non-breaching Party shall be entitled to specific
                            performance and injunctive relief for any such
                            breach.
                          </li>
                          <li>
                            Notwithstanding the above, the restrictions of this
                            Section shall not apply to information that: (a) was
                            independently developed by the Recipient without any
                            use of the Confidential Information of the Discloser
                            and by employees or other agents of (or independent
                            contractors hired by) the Recipient who have not
                            been exposed to the Confidential Information; (b)
                            becomes known to the Recipient, without restriction,
                            from a third party without breach of an obligation
                            of confidentiality and who had a right to disclose
                            it; (c) was in the public domain at the time it was
                            disclosed or becomes in the public domain through no
                            act or omission of the Recipient; (d) was rightfully
                            known to the Recipient, without restriction, at the
                            time of disclosure; or (e) is disclosed pursuant to
                            the order or requirement of a court, administrative
                            agency, or other governmental body; provided,
                            however, that the Recipient shall provide notice as
                            soon as is reasonably practicable to the Discloser
                            and shall provide reasonable assistance to the
                            Discloser to obtain a protective order or otherwise
                            prevent public disclosure of such Confidential
                            Information and such Confidential Information shall
                            only lose its confidentiality protection for
                            purposes of such legal disclosure.
                          </li>
                        </ol>
                        <br></br>
                        <li>Miscellaneous.</li>
                        <ol>
                          <li>
                            Waiver and Amendment. No modification, amendment or
                            waiver of any provision of this Agreement shall be
                            effective unless in writing and signed by both
                            Parties. Order confirmation terms or similar
                            documents or terms are rejected. The failure of
                            either Party to seek relief for the other Party"s
                            breach of any duty under this Agreement shall not
                            waive any right of the non-breaching Party to seek
                            relief for any subsequent breach.
                          </li>
                          <li>
                            Relationship. The Parties and their respective
                            personnel, are and shall be independent contractors,
                            and neither Party by virtue of this Agreement shall
                            have any right, power or authority to act or create
                            any obligation, express or implied, on behalf of the
                            other Party.{" "}
                          </li>
                          <li>
                            Assignment. The Parties shall not assign or transfer
                            this Agreement without the express prior written
                            consent of the other, which consent shall not be
                            unreasonably withheld or delayed. Notwithstanding
                            the foregoing, either Party may assign its rights
                            and obligations hereunder to a successor in the
                            event of a change of control transaction such as a
                            merger, reorganization, acquisition, sale of all or
                            substantially all of its assets or similar
                            transaction.
                          </li>
                          <li>
                            Force Majeure. Neither Party shall be deemed in
                            default of the Agreement to the extent that
                            performance of its obligations or attempts to cure
                            any breach are delayed, restricted or prevented by
                            reason of any act of God, fire, natural disaster,
                            act of government, strikes or labor disputes,
                            inability to provide raw materials, power or
                            supplies, or any other similar act or condition
                            beyond the reasonable control of the Parties,
                            provided that the Party so affected uses
                            commercially reasonable efforts to avoid and remove
                            the causes of nonperformance and continues
                            performance hereunder immediately after those causes
                            are removed.
                          </li>
                          <li>
                            Counterparts. This Agreement may be executed in
                            several counterparts, all of which taken together
                            shall constitute the entire Agreement between the
                            Parties. This Agreement may be executed and
                            delivered with facsimile signatures with the same
                            force and effect as original signatures.
                          </li>
                          <li>
                            Governing Law. The construction, validity and
                            performance of this Agreement shall be governed by,
                            and construed in accordance with, the laws of the
                            State of Minnesota, without regard to principles
                            thereof relating to conflict of laws. Any dispute
                            arising under or related to this Agreement shall be
                            venued in and subject to the jurisdiction of the
                            state and federal courts located in Minnesota and
                            each Party hereby submits to the jurisdiction and
                            proper venue of such courts.
                          </li>
                          <li>
                            Entire Agreement. Each Party to this Agreement
                            acknowledges that this Agreement constitutes the
                            entire Agreement of the Parties with regard to the
                            subject matter addressed in this Agreement;
                            supersedes all prior or contemporaneous agreements,
                            discussions, or representations, whether oral or
                            written, with respect to the subject matter of this
                            Agreement; and cannot be amended except by a writing
                            signed by all Parties.
                          </li>
                        </ol>
                      </ol>
                      <br></br>
                      <h5>How To Contact Us</h5>
                      If you have any questions about our Terms and Conditions,
                      please contact us at customer.service@pearcommerce.com.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
